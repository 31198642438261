<template>
	<div class="pageContainer">
		<a-row class="lawStudy">
			<a-col :span="4" class="leftMenu">
				<a-directory-tree multiple @select="onSelect" :defaultExpandedKeys="['0-0']" :selectedKeys="selectedKeys">
					<a-tree-node v-for="item in treeData" :key="item.key" :title="item.title">
						<a-tree-node v-for="treeItem in item.children" :key="treeItem.key" :title="treeItem.abbreviation" is-leaf />
					</a-tree-node>
				</a-directory-tree>
			</a-col>
			<a-col :span="20">
				<iframe frameBorder="0" style="width: 100%; height: 100%" :src="currentSelectedObj.href" />
			</a-col>
		</a-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			treeData: [
				{
					title: '国家法律',
					key: '0-0',
					children: [
						{
							title: '中华人民共和国广告法（2018修正）',
							abbreviation: '广告法',
							key: '0-0-0',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%B9%BF%E5%91%8A%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国反不正当竞争法（2019修正）',
							abbreviation: '反不正当竞争',
							key: '0-0-1',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%8F%8D%E4%B8%8D%E6%AD%A3%E5%BD%93%E7%AB%9E%E4%BA%89.pdf',
						},
						{
							title: '中华人民共和国中医药法',
							abbreviation: '中医药法',
							key: '0-0-2',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E4%B8%AD%E5%8C%BB%E8%8D%AF%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国商标法（2019修订）',
							abbreviation: '商标法',
							key: '0-0-3',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%95%86%E6%A0%87%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国药品管理法（2019修订）',
							abbreviation: '药品管理法',
							key: '0-0-4',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E8%8D%AF%E5%93%81%E7%AE%A1%E7%90%86%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国产品质量法',
							abbreviation: '产品质量法',
							key: '0-0-5',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E4%BA%A7%E5%93%81%E8%B4%A8%E9%87%8F%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国消费者权益保护法',
							abbreviation: '消费者权益',
							key: '0-0-6',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%B6%88%E8%B4%B9%E8%80%85%E6%9D%83%E7%9B%8A.pdf',
						},
						{
							title: '中华人民共和国专利法',
							abbreviation: '专利法',
							key: '0-0-7',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E4%B8%93%E5%88%A9%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国城市房地产管理法',
							abbreviation: '房地产管理法',
							key: '0-0-8',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%9F%8E%E5%B8%82%E6%88%BF%E5%9C%B0%E4%BA%A7%E7%AE%A1%E7%90%86%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国反垄断法',
							abbreviation: '反垄断法',
							key: '0-0-9',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%8F%8D%E5%9E%84%E6%96%AD%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国价格法',
							abbreviation: '价格法',
							key: '0-0-10',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E4%BB%B7%E6%A0%BC%E6%B3%95.pdf',
						},
						{
							title: '中华人民共和国食品安全法(2021 修正)',
							abbreviation: '食品安全法',
							key: '0-0-11',
							isLeaf: true,
							href:
								'https://resources.xbov.cn/hx/study_center/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E9%A3%9F%E5%93%81%E5%AE%89%E5%85%A8%E6%B3%95%282021%E4%BF%AE%E6%AD%A3%29.pdf',
						},
					],
				},
				{
					title: '行政法规',
					key: '0-1',
					children: [
						{
							title: '房地产广告发布规定',
							abbreviation: '房地产广告',
							key: '0-1-0',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%88%BF%E5%9C%B0%E4%BA%A7%E5%B9%BF%E5%91%8A.pdf',
						},
						{
							title: '农药广告审查发布标准',
							abbreviation: '农药广告',
							key: '0-1-2',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%86%9C%E8%8D%AF%E5%B9%BF%E5%91%8A.pdf',
						},
						{
							title: '兽药管理条例（2020修订）',
							abbreviation: '兽药管理条例',
							key: '0-1-3',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%85%BD%E8%8D%AF%E7%AE%A1%E7%90%86%E6%9D%A1%E4%BE%8B.pdf',
						},
						{
							title: '兽药广告审查发布标准',
							abbreviation: '兽药广告',
							key: '0-1-4',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%85%BD%E8%8D%AF%E5%B9%BF%E5%91%8A_.pdf',
						},
						{
							title: '药品、医疗器械、保健食品、特殊医学用途配方食品广告审查管理暂行办法',
							abbreviation: '三品一械',
							key: '0-1-5',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E4%B8%89%E5%93%81%E4%B8%80%E6%A2%B0.pdf',
						},
						{
							title: '医疗广告管理办法（2006修订）',
							abbreviation: '医疗广告',
							key: '0-1-6',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%8C%BB%E7%96%97%E5%B9%BF%E5%91%8A.pdf',
						},
						{
							title: '医疗机构管理条例',
							abbreviation: '医疗机构管理',
							key: '0-1-7',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%8C%BB%E7%96%97%E6%9C%BA%E6%9E%84%E7%AE%A1%E7%90%86%E6%9D%A1%E4%BE%8B.pdf',
						},
						{
							title: '国家工商行政管理总局、中央宣传部、国务院新闻办公室等关于印发《大众传播媒介广告发布审查规定》的通知',
							abbreviation: '大众传播媒介',
							key: '0-1-8',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%A4%A7%E4%BC%97%E4%BC%A0%E6%92%AD%E5%AA%92%E4%BB%8B.pdf',
						},
						{
							title: '化妆品监督管理条例',
							abbreviation: '化妆品监督管理条例',
							key: '0-1-9',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%8C%96%E5%A6%86%E5%93%81%E7%9B%91%E7%9D%A3%E7%AE%A1%E7%90%86%E6%9D%A1%E4%BE%8B.pdf',
						},
					],
				},
				{
					title: '地方法规',
					key: '0-2',
					children: [
						{
							title: '湖南省实施广告法办法',
							abbreviation: '湖南广告办法',
							key: '0-2-15',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%B9%96%E5%8D%97%E7%9C%81%E5%AE%9E%E6%96%BD%E5%B9%BF%E5%91%8A%E6%B3%95%E5%8A%9E%E6%B3%95.pdf',
						},
						{
							title: '长沙市户外广告设置管理办法',
							abbreviation: '长沙市',
							key: '0-2-0',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E9%95%BF%E6%B2%99%E5%B8%82.pdf',
						},
						{
							title: '株洲市户外广告设置管理办法',
							abbreviation: '株洲市',
							key: '0-2-1',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%A0%AA%E6%B4%B2%E5%B8%82.pdf',
						},
						{
							title: '张家界市户外广告设置管理办法',
							abbreviation: '张家界市',
							key: '0-2-2',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%BC%A0%E5%AE%B6%E7%95%8C.pdf',
						},
						{
							title: '岳阳市户外广告设置管理办法',
							abbreviation: '岳阳市',
							key: '0-2-3',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%B2%B3%E9%98%B3%E5%B8%82.pdf',
						},
						{
							title: '永州市户外广告设置管理办法',
							abbreviation: '永州市',
							key: '0-2-4',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%B0%B8%E5%B7%9E%E5%B8%82.pdf',
						},
						{
							title: '益阳市户外广告设置管理办法',
							abbreviation: '益阳市',
							key: '0-2-5',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E7%9B%8A%E9%98%B3%E5%B8%82.pdf',
						},
						{
							title: '湘潭市户外广告设置管理办法',
							abbreviation: '湘潭市',
							key: '0-2-6',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%B9%98%E6%BD%AD%E5%B8%82.pdf',
						},
						{
							title: '邵阳市户外广告设置管理办法',
							abbreviation: '邵阳市',
							key: '0-2-7',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E9%82%B5%E9%98%B3%E5%B8%82.pdf',
						},
						{
							title: '娄底市户外广告设置管理办法',
							abbreviation: '娄底市',
							key: '0-2-8',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%A8%84%E5%BA%95%E5%B8%82.pdf',
						},
						{
							title: '怀化市户外广告设置管理办法',
							abbreviation: '怀化市',
							key: '0-2-9',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%80%80%E5%8C%96%E5%B8%82.pdf',
						},
						{
							title: '衡阳市户外广告设置管理办法',
							abbreviation: '衡阳市',
							key: '0-2-10',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E8%A1%A1%E9%98%B3%E5%B8%82.pdf',
						},
						{
							title: '郴州市户外广告设置管理办法',
							abbreviation: '郴州市',
							key: '0-2-11',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E9%83%B4%E5%B7%9E%E5%B8%82.pdf',
						},
						{
							title: '常德市户外广告设置管理办法',
							abbreviation: '常德市',
							key: '0-2-12',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E5%B8%B8%E5%BE%B7%E5%B8%82.pdf',
						},
						{
							title: '湘西土家族苗族自治州',
							abbreviation: '湘西自治州',
							key: '0-2-13',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E6%B9%98%E8%A5%BF.pdf',
						},
						{
							title: '湖南省公益广告促进和管理暂行办法',
							abbreviation: '湖南公益广告',
							key: '0-2-14',
							isLeaf: true,
							href:
								'https://resources.xbov.cn/hx/study_center/%E6%B9%96%E5%8D%97%E5%85%AC%E7%9B%8A%E5%B9%BF%E5%91%8A%E4%BF%83%E8%BF%9B%E5%92%8C%E7%AE%A1%E7%90%86%E6%9A%82%E8%A1%8C%E5%8A%9E%E6%B3%95.pdf',
						},
					],
				},
				{
					title: '行业规范',
					key: '0-3',
					children: [
						{
							title: '网络直播营销行为规范',
							abbreviation: '网络直播',
							key: '0-3-1',
							isLeaf: true,
							href: 'https://resources.xbov.cn/hx/study_center/%E7%BD%91%E7%BB%9C%E7%9B%B4%E6%92%AD.pdf',
						},
					],
				},
				// {
				// 	title: '安检公告',
				// 	key: '0-5',
				// 	children: [
				// 		{
				// 			title: '2022.1.6筑综执局发〔2022〕2号',
				// 			abbreviation: '2022.1.6筑综执局发〔2022〕2号',
				// 			key: '0-5-1',
				// 			isLeaf: true,
				// 			href: 'https://resources.xbov.cn/help/adtoo/2022.1.6%E7%AD%91%E7%BB%BC%E6%89%A7%E5%B1%80%E5%8F%91%E3%80%942022%E3%80%952%E5%8F%B7.pdf',
				// 		},
				// 		{
				// 			title: '2022.3.26市综合行政执法局关于开展户外广告、门头牌匾设施专项行动大检查的通知实收2022.6.28',
				// 			abbreviation: '2022.3.26市综合行政执法局关于开展户外广告、门头牌匾设施专项行动大检查的通知实收2022.6.28',
				// 			key: '0-5-2',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/2022.3.26%E5%B8%82%E7%BB%BC%E5%90%88%E8%A1%8C%E6%94%BF%E6%89%A7%E6%B3%95%E5%B1%80%E5%85%B3%E4%BA%8E%E5%BC%80%E5%B1%95%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E3%80%81%E9%97%A8%E5%A4%B4%E7%89%8C%E5%8C%BE%E8%AE%BE%E6%96%BD%E4%B8%93%E9%A1%B9%E8%A1%8C%E5%8A%A8%E5%A4%A7%E6%A3%80%E6%9F%A5%E7%9A%84%E9%80%9A%E7%9F%A5%E5%AE%9E%E6%94%B62022.6.28.pdf',
				// 		},
				// 		{
				// 			title: '2022.5.20筑综执局发〔2022〕89号',
				// 			abbreviation: '2022.5.20筑综执局发〔2022〕89号',
				// 			key: '0-5-3',
				// 			isLeaf: true,
				// 			href: 'https://resources.xbov.cn/help/adtoo/2022.5.20%E7%AD%91%E7%BB%BC%E6%89%A7%E5%B1%80%E5%8F%91%E3%80%942022%E3%80%9589%E5%8F%B7%C2%A0.pdf',
				// 		},
				// 		{
				// 			title: '2022.7.14关于进一步深入开展户外广告门头牌匾风险排查整治的通知',
				// 			abbreviation: '2022.7.14关于进一步深入开展户外广告门头牌匾风险排查整治的通知',
				// 			key: '0-5-4',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/2022.7.14%E5%85%B3%E4%BA%8E%E8%BF%9B%E4%B8%80%E6%AD%A5%E6%B7%B1%E5%85%A5%E5%BC%80%E5%B1%95%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E9%97%A8%E5%A4%B4%E7%89%8C%E5%8C%BE%E9%A3%8E%E9%99%A9%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E7%9A%84%E9%80%9A%E7%9F%A5.pdf',
				// 		},
				// 		{
				// 			title: '关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
				// 			abbreviation: '关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
				// 			key: '0-5-5',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/%E5%85%B3%E4%BA%8E%E8%B4%AF%E5%BD%BB%E8%90%BD%E5%AE%9E%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E9%9A%90%E6%82%A3%E9%9B%86%E4%B8%AD%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.pdf',
				// 		},
				// 		{
				// 			title: '关于转发《关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知》（区市县）',
				// 			abbreviation: '关于转发《关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知》（区市县）',
				// 			key: '0-5-6',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/%E5%85%B3%E4%BA%8E%E8%BD%AC%E5%8F%91%E3%80%8A%E5%85%B3%E4%BA%8E%E8%B4%AF%E5%BD%BB%E8%90%BD%E5%AE%9E%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E9%9A%90%E6%82%A3%E9%9B%86%E4%B8%AD%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5%E3%80%8B%EF%BC%88%E5%8C%BA%E5%B8%82%E5%8E%BF%EF%BC%89.pdf',
				// 		},
				// 		{
				// 			title: '城市户外广告和招牌设施技术标准',
				// 			abbreviation: '城市户外广告和招牌设施技术标准',
				// 			key: '0-5-7',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/%E5%9F%8E%E5%B8%82%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E6%8A%80%E6%9C%AF%E6%A0%87%E5%87%86.pdf',
				// 		},
				// 		{
				// 			title: '市综合行政执法局关于全面开展大型户外广告设施隐患排查的紧急通知(副本)',
				// 			abbreviation: '市综合行政执法局关于全面开展大型户外广告设施隐患排查的紧急通知(副本)',
				// 			key: '0-5-8',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/%E5%B8%82%E7%BB%BC%E5%90%88%E8%A1%8C%E6%94%BF%E6%89%A7%E6%B3%95%E5%B1%80%E5%85%B3%E4%BA%8E%E5%85%A8%E9%9D%A2%E5%BC%80%E5%B1%95%E5%A4%A7%E5%9E%8B%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E8%AE%BE%E6%96%BD%E9%9A%90%E6%82%A3%E6%8E%92%E6%9F%A5%E7%9A%84%E7%B4%A7%E6%80%A5%E9%80%9A%E7%9F%A5%28%E5%89%AF%E6%9C%AC%29.pdf',
				// 		},
				// 		{
				// 			title: '附件1：关于开展城市户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
				// 			abbreviation: '附件1：关于开展城市户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
				// 			key: '0-5-9',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/%E9%99%84%E4%BB%B61%EF%BC%9A%E5%85%B3%E4%BA%8E%E5%BC%80%E5%B1%95%E5%9F%8E%E5%B8%82%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E9%9A%90%E6%82%A3%E9%9B%86%E4%B8%AD%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.pdf',
				// 		},
				// 		{
				// 			title: '附件：2、3户外广告和招牌设施设置安全排查统计表',
				// 			abbreviation: '附件：2、3户外广告和招牌设施设置安全排查统计表',
				// 			key: '0-5-10',
				// 			isLeaf: true,
				// 			href:
				// 				'https://resources.xbov.cn/help/adtoo/%E9%99%84%E4%BB%B6%EF%BC%9A2%E3%80%813%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E6%8E%92%E6%9F%A5%E7%BB%9F%E8%AE%A1%E8%A1%A8.pdf',
				// 		},
				// 	],
				// },
				{
					title: '违法监测代码',
					key: '0-4',
					children: [
						{
							title: '广告违法行为监测编码（20200729更新版）',
							abbreviation: '违法监测代码',
							key: '0-4-1',
							isLeaf: true,
							href:
								'https://resources.xbov.cn/hx/study_center/%E5%B9%BF%E5%91%8A%E8%BF%9D%E6%B3%95%E8%A1%8C%E4%B8%BA%E7%9B%91%E6%B5%8B%E7%BC%96%E7%A0%81%EF%BC%8820200729%E6%9B%B4%E6%96%B0%E7%89%88%EF%BC%89.pdf',
						},
					],
				},
			],
			selectedKeys: ['0-0-0'],
			currentSelectedObj: {},
		};
	},
	created() {
		this.currentSelectedObj = this.treeData[0].children[0];
	},
	methods: {
		onSelect(value, e) {
			this.selectedKeys = value;
			let currentSelectedObj = {};
			this.treeData.map(item => {
				item.children.map(treeItem => {
					if (treeItem.key == value) {
						currentSelectedObj = treeItem;
					}
				});
			});
			if (!currentSelectedObj.href) {
				return;
			}
			this.currentSelectedObj = currentSelectedObj;
		},
	},
};
</script>

<style lang="less" scoped>
.pageContainer {
	.lawStudy {
		padding: 16px;
		height: 100%;
		background: #fff;
		.leftMenu {
			overflow: hidden auto;
		}
		div {
			height: 100%;
		}
	}
}
</style>
