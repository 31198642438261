<template>
	<div class="pageContainer">
		<a-row class="lawStudy">
			<a-col :span="10" class="leftMenu">
				<a-directory-tree multiple @select="onSelect" :defaultExpandedKeys="['0-0']" :selectedKeys="selectedKeys">
					<a-tree-node v-for="item in treeData" :key="item.key" :title="item.title" is-leaf>
						<!-- <a-tree-node v-for="treeItem in item.children" :key="treeItem.key" :title="treeItem.abbreviation" /> -->
					</a-tree-node>
				</a-directory-tree>
			</a-col>
			<a-col :span="14">
				<!-- <iframe frameBorder="0" style="width: 97%; height: 84%" :src="currentSelectedObj.href" /> -->
				<iframe frameBorder="0" style="width: 100%; height: 100%" :src="currentSelectedObj.href" />
			</a-col>
		</a-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			treeData: [
				{
					title: '2022.1.6筑综执局发〔2022〕2号',
					abbreviation: '2022.1.6筑综执局发〔2022〕2号',
					key: '0-0-0',
					isLeaf: true,
					href: 'https://resources.xbov.cn/help/adtoo/2022.1.6%E7%AD%91%E7%BB%BC%E6%89%A7%E5%B1%80%E5%8F%91%E3%80%942022%E3%80%952%E5%8F%B7.pdf',
				},
				{
					title: '2022.3.26市综合行政执法局关于开展户外广告、门头牌匾设施专项行动大检查的通知实收2022.6.28',
					abbreviation: '2022.3.26市综合行政执法局关于开展户外广告、门头牌匾设施专项行动大检查的通知实收2022.6.28',
					key: '0-0-1',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/2022.3.26%E5%B8%82%E7%BB%BC%E5%90%88%E8%A1%8C%E6%94%BF%E6%89%A7%E6%B3%95%E5%B1%80%E5%85%B3%E4%BA%8E%E5%BC%80%E5%B1%95%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E3%80%81%E9%97%A8%E5%A4%B4%E7%89%8C%E5%8C%BE%E8%AE%BE%E6%96%BD%E4%B8%93%E9%A1%B9%E8%A1%8C%E5%8A%A8%E5%A4%A7%E6%A3%80%E6%9F%A5%E7%9A%84%E9%80%9A%E7%9F%A5%E5%AE%9E%E6%94%B62022.6.28.pdf',
				},
				{
					title: '2022.5.20筑综执局发〔2022〕89号',
					abbreviation: '2022.5.20筑综执局发〔2022〕89号',
					key: '0-0-2',
					isLeaf: true,
					href: 'https://resources.xbov.cn/help/adtoo/2022.5.20%E7%AD%91%E7%BB%BC%E6%89%A7%E5%B1%80%E5%8F%91%E3%80%942022%E3%80%9589%E5%8F%B7%C2%A0.pdf',
				},
				{
					title: '2022.7.14关于进一步深入开展户外广告门头牌匾风险排查整治的通知',
					abbreviation: '2022.7.14关于进一步深入开展户外广告门头牌匾风险排查整治的通知',
					key: '0-0-3',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/2022.7.14%E5%85%B3%E4%BA%8E%E8%BF%9B%E4%B8%80%E6%AD%A5%E6%B7%B1%E5%85%A5%E5%BC%80%E5%B1%95%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E9%97%A8%E5%A4%B4%E7%89%8C%E5%8C%BE%E9%A3%8E%E9%99%A9%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E7%9A%84%E9%80%9A%E7%9F%A5.pdf',
				},
				{
					title: '关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
					abbreviation: '关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
					key: '0-0-4',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/%E5%85%B3%E4%BA%8E%E8%B4%AF%E5%BD%BB%E8%90%BD%E5%AE%9E%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E9%9A%90%E6%82%A3%E9%9B%86%E4%B8%AD%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.pdf',
				},
				{
					title: '关于转发《关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知》(区市县)',
					abbreviation: '关于转发《关于贯彻落实户外广告和招牌设施设置安全隐患集中排查整治工作的通知》(区市县)',
					key: '0-0-5',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/%E5%85%B3%E4%BA%8E%E8%BD%AC%E5%8F%91%E3%80%8A%E5%85%B3%E4%BA%8E%E8%B4%AF%E5%BD%BB%E8%90%BD%E5%AE%9E%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E9%9A%90%E6%82%A3%E9%9B%86%E4%B8%AD%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5%E3%80%8B%EF%BC%88%E5%8C%BA%E5%B8%82%E5%8E%BF%EF%BC%89.pdf',
				},
				{
					title: '城市户外广告和招牌设施技术标准',
					abbreviation: '城市户外广告和招牌设施技术标准',
					key: '0-0-6',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/%E5%9F%8E%E5%B8%82%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E6%8A%80%E6%9C%AF%E6%A0%87%E5%87%86.pdf',
				},
				{
					title: '市综合行政执法局关于全面开展大型户外广告设施隐患排查的紧急通知(副本)',
					abbreviation: '市综合行政执法局关于全面开展大型户外广告设施隐患排查的紧急通知(副本)',
					key: '0-0-7',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/%E5%B8%82%E7%BB%BC%E5%90%88%E8%A1%8C%E6%94%BF%E6%89%A7%E6%B3%95%E5%B1%80%E5%85%B3%E4%BA%8E%E5%85%A8%E9%9D%A2%E5%BC%80%E5%B1%95%E5%A4%A7%E5%9E%8B%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E8%AE%BE%E6%96%BD%E9%9A%90%E6%82%A3%E6%8E%92%E6%9F%A5%E7%9A%84%E7%B4%A7%E6%80%A5%E9%80%9A%E7%9F%A5%28%E5%89%AF%E6%9C%AC%29.pdf',
				},
				{
					title: '附件1：关于开展城市户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
					abbreviation: '附件1：关于开展城市户外广告和招牌设施设置安全隐患集中排查整治工作的通知',
					key: '0-0-8',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/%E9%99%84%E4%BB%B61%EF%BC%9A%E5%85%B3%E4%BA%8E%E5%BC%80%E5%B1%95%E5%9F%8E%E5%B8%82%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E9%9A%90%E6%82%A3%E9%9B%86%E4%B8%AD%E6%8E%92%E6%9F%A5%E6%95%B4%E6%B2%BB%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.pdf',
				},
				{
					title: '附件：2、3户外广告和招牌设施设置安全排查统计表',
					abbreviation: '附件：2、3户外广告和招牌设施设置安全排查统计表',
					key: '0-0-9',
					isLeaf: true,
					href:
						'https://resources.xbov.cn/help/adtoo/%E9%99%84%E4%BB%B6%EF%BC%9A2%E3%80%813%E6%88%B7%E5%A4%96%E5%B9%BF%E5%91%8A%E5%92%8C%E6%8B%9B%E7%89%8C%E8%AE%BE%E6%96%BD%E8%AE%BE%E7%BD%AE%E5%AE%89%E5%85%A8%E6%8E%92%E6%9F%A5%E7%BB%9F%E8%AE%A1%E8%A1%A8.pdf',
				},
			],
			selectedKeys: ['0-0-0'],
			currentSelectedObj: {},
		};
	},
	created() {
		this.currentSelectedObj = this.treeData[0];
	},
	methods: {
		onSelect(value, e) {
			this.selectedKeys = value;
			let currentSelectedObj = {};
			this.treeData.map(item => {
				if (item.key == value) {
					currentSelectedObj = item;
				}
				// item.children.map(treeItem => {

				// });
			});
			if (!currentSelectedObj.href) {
				return;
			}
			this.currentSelectedObj = currentSelectedObj;
		},
	},
};
</script>

<style lang="less" scoped>
.pageContainer {
	.lawStudy {
		padding: 16px;
		padding-left: 0px;
		height: 100%;
		background: #fff;
		.leftMenu {
			overflow: hidden auto;
			// margin-top: 5px;
			padding-left: 16px;
		}
		div {
			height: 100%;
		}
		// .iframe {
		// 	position: fixed;
		// 	z-index: 1;
		// 	right: -15px;
		// 	top: 90px;
		// }
	}
}
</style>
